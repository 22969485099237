import {styles} from "./HomeAssets";

const HomeInfo = () => {
    return (
        <div
        className={`${styles.section} ${styles.orange}`}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-3 col-12 mb-sm-0 mb-5">
                        <div className={`d-flex ${styles.info}`} style={{alignItems: "center"}}>
                            <h1 className="me-2 fw-bold" style={{fontSize: "3em !important"}}>19</h1>
                            <span style={{fontSize: "1em"}}>Kami sudah berdiri <br /> selama 19 Tahun.</span>
                        </div>
                    </div>
                    <div className="col-sm-3 col-12 mb-sm-0 mb-5">
                        <div className={`d-flex ${styles.info} ${styles.end}`} style={{alignItems: "center"}}>
                            <h1 className="me-2 fw-bold" style={{fontSize: "3em !important"}}>15K+</h1>
                            <span style={{fontSize: "1em"}}>Lebih dari 15.000 Kupon <br /> Ayam Gepuk Gratis <br /> Telah Kami Cetak.</span>
                        </div>
                    </div>
                    <div className="col-sm-3 col-12 mb-sm-0 mb-5">
                        <div className={`d-flex ${styles.info}`} style={{alignItems: "center"}}>
                            <h1 className="me-2 fw-bold" style={{fontSize: "3em !important"}}>35+</h1>
                            <span style={{fontSize: "1em"}}>Lebih dari 35 menu <br /> tersedia yang berganti <br /> setiap hari.</span>
                        </div>
                    </div>
                    <div className="col-sm-3 col-12 mb-sm-0 mb-5">
                        <div className={`d-flex ${styles.info} ${styles.end}`} style={{alignItems: "center"}}>
                            <h1 className="me-2 fw-bold" style={{fontSize: "3em !important"}}>150+</h1>
                            <span style={{fontSize: "1em"}}>Lebih dari 150 pelanggan <br /> yang terdaftar pada sistem kami.</span>
                        </div>
                    </div>
                </div>
            </div>

      </div>
    )
}

export default HomeInfo;