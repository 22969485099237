import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import NavbarComponent from './components/NavbarComponent';
import style from './assets/css/style.module.css';

const App = ({children}) => {
  return (
    <div className={style.app}>
      <NavbarComponent />
      {children}
    </div>
  );
}

export default App;
