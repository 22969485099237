import { Link } from "react-router-dom";
import { styles, ayamBakar, ayamGepuk, ayamPenyet } from "./HomeAssets";

const HomeMainMenu = () => {
  return (
    <div className={`${styles.section} ${styles.main_menu}`}>
      <div className="container">
        <h2>Menu Utama</h2>
        <p>
            Kami merupakan warung nasi yang menyediakan berbagai macam menu makanan pada umumnya, namun kami memiliki signature menu yang menjadi andalan kami, yaitu Ayam Gepuk, Ayam Bakar, dan Ayam Penyet. Untuk menu selengkapnya silahkan <Link to="/daftar-menu">klik disini</Link>.
        </p>
        <div className="row justify-content-center">
            <div className="col-sm-4 col-6">
                <img src={ayamGepuk} alt="" />
            </div>
            <div className="col-sm-4 col-6">
                <img src={ayamBakar} alt="" />
            </div>
            <div className="col-sm-4 col-6">
                <img src={ayamPenyet} alt="" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMainMenu;
