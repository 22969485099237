import { styles } from "../components/HomeAssets";
import HomeFooter from "../components/HomeFooter";
const Menu = () => {
  return (
    <div>
      <div className={`${styles.section} ${styles.menu}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>
                Daftar Menu Makanan dan Minuman <br /> Warung Nasi Zein Pemalang
              </h1>

              <br />

              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Nama Menu</th>
                      <th>Harga</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Nasi Putih</td>
                      <td>Rp5.000</td>
                    </tr>
                    <tr>
                      <td>Ayam Gepuk (Tanpa Nasi)</td>
                      <td>Rp13.000</td>
                    </tr>
                    <tr>
                      <td>Ayam Bakar (Tanpa Nasi)</td>
                      <td>Rp8.000</td>
                    </tr>
                    <tr>
                      <td>Ayam Bakar Besar (Tanpa Nasi)</td>
                      <td>Rp13.000</td>
                    </tr>
                    <tr>
                      <td>Paket Ayam Gepuk + Nasi</td>
                      <td>Rp17.000</td>
                    </tr>
                    <tr>
                      <td>Paket Ayam Bakar + Nasi</td>
                      <td>Rp12.000</td>
                    </tr>
                    <tr>
                      <td>Paket Ayam Bakar Besar + Nasi</td>
                      <td>Rp17.000</td>
                    </tr>
                    <tr>
                      <td>Tahu Goreng</td>
                      <td>Rp2.000</td>
                    </tr>
                    <tr>
                      <td>Telur Dadar</td>
                      <td>Rp4.000</td>
                    </tr>
                    <tr>
                      <td>Telur Balado</td>
                      <td>Rp4.000</td>
                    </tr>
                    <tr>
                      <td>Telur Asin</td>
                      <td>Rp5.000</td>
                    </tr>
                    <tr>
                      <td>Ikan Tongkol</td>
                      <td>Rp5.000</td>
                    </tr>
                    <tr>
                      <td>Ikan Cue</td>
                      <td>Rp7.000</td>
                    </tr>
                    <tr>
                      <td>Tempe Goreng</td>
                      <td>Rp1.000</td>
                    </tr>
                    <tr>
                      <td>Tempe Goreng Tepung</td>
                      <td>Rp1.000</td>
                    </tr>
                    <tr>
                      <td>Sayur Matang</td>
                      <td>Rp5.000</td>
                    </tr>
                    <tr>
                      <td>Es Teh Manis Cup Besar</td>
                      <td>Rp4.000</td>
                    </tr>
                    <tr>
                      <td>Es Jeruk</td>
                      <td>Rp5.000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};
export default Menu;
