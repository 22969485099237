import { styles, takeaway, delivery, dinein } from "./HomeAssets";

const HomeServices = () => {
  return (
    <div className={`${styles.section} ${styles.services} ${styles.orange}`}>
      <div className="container">
        <h2>Layanan Kami</h2>
        <br />
        <div className="row gap-0">
          <div className="col">
            <div
              className={`card mx-auto mb-sm-0 mb-2 ${styles.card}`}
              style={{ width: "18rem" }}
            >
              <img src={dinein} class="card-img-top" alt="..." />
              <div className="card-body">
                <h3>Makan Di Tempat</h3>
                <p>
                  Kami menyediakan tempat makan yang nyaman dan bersih, dengan
                  fasilitas yang lengkap.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`card mx-auto mb-sm-0 mb-2 ${styles.card}`}
              style={{ width: "18rem" }}
            >
                <img src={takeaway} class="card-img-top" alt="..." />
              <div className="card-body">
                <h3>Takeaway</h3>
                <p>
                  Kami menyediakan layanan pembungkus untuk kamu yang ingin
                  membawa pulang makanan.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className={`card mx-auto mb-sm-0 mb-2 ${styles.card}`}
              style={{ width: "18rem" }}
            >
                <img src={delivery} class="card-img-top" alt="..." />
              <div className="card-body">
                <h3>Catering</h3>
                <p>
                  Kami menyediakan layanan catering untuk kamu yang ingin
                  memesan makanan dalam jumlah besar dengan gratis ongkir
                  (syarat dan ketentuan yang berlaku).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
