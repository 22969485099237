import { styles, iconCuciTangan, iconHp, iconKipas, iconTv, iconWifi } from "./HomeAssets";

const HomeFacility = () => {
  return (
    <div className={`${styles.section} ${styles.facility}`}>
      <div className="container">
        <h2>Fasilitas</h2>
        <div className="row">
            <div className="col-sm-12">
                <div className="d-flex mb-4">
                    <div className={styles.facility_icon}>
                        <img src={iconCuciTangan} alt="" />
                    </div>
                    <div className={styles.desc}>
                        <h3>Wastafel</h3>
                        <p>
                            Kami menyediakan wastafel untuk memudahkan kamu dalam mencuci tangan, karena mencuci tangan dengan air mengalir dan sabun lebih higienis. 
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className={`d-flex ${styles.end} mb-4`}>
                    <div className={styles.desc}>
                        <h3>Kipas Angin</h3>
                        <p>
                            Kami menyediakan kipas angin untuk memberikan kenyamanan bagi kamu yang sedang makan di tempat kami. 
                        </p>
                    </div>
                    <div className={styles.facility_icon}>
                        <img src={iconKipas} alt="" />
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="d-flex mb-4">
                    <div className={styles.facility_icon}>
                        <img src={iconTv} alt="" />
                    </div>
                    <div className={styles.desc}>
                        <h3>Hiburan</h3>
                        <p>
                            Kami menyediakan Smart TV untuk memberikan hiburan bagi kamu yang sedang makan di tempat kami, berupa pemutaran Youtube, Spotify, maupun Netflix.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className={`d-flex ${styles.end} mb-4`}>
                    <div className={styles.desc}>
                        <h3>Aplikasi PWA</h3>
                        <p>
                            Kami memiliki aplikasi berbasis <i>Progressive Web App's (PWA's)</i> yang digunakan sebagai pusat informasi, klaim kupon, promo, dan pemesanan. Yang dapat diakses melalui browser, di install pada Android, iOS, maupun desktop.
                        </p>
                    </div>
                    <div className={styles.facility_icon}>
                        <img src={iconHp} alt="" />
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="d-flex mb-4">
                    <div className={styles.facility_icon}>
                        <img src={iconWifi} alt="" />
                    </div>
                    <div className={styles.desc}>
                        <h3>WiFi</h3>
                        <p>
                            Kami menyediakan WiFi gratis bagi kamu yang sedang makan di tempat kami, agar kamu tetap terhubung dengan internet.
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFacility;
