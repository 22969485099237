import { styles } from "./HomeAssets";

const HomeFooter = () => {
  return (
    <footer id="contact" className={`${styles.section} ${styles.footer}`}>
      <div className="container">
        <div className="row">
          <div className="col-sm-3 col-12">
            <h4>Alamat</h4>
            <p>
              Jl. KH Agus Salim Gg. Sawo 2 No. 7, RT01/05 Kel. Poris Plawad,
              Kec. Cipondoh, Tangerang, Banten 15141
            </p>
            <br />
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.677254099772!2d106.66251716961057!3d-6.1697042662763915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f8efc6d0d645%3A0xe18368471a552c5f!2sWARUNG%20NASI%20ZEIN%20PEMALANG!5e0!3m2!1sid!2sid!4v1708703144232!5m2!1sid!2sid"
              width="300"
              height="150"
              style={{ border: 0, borderRadius: "20px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-sm-3 col-12">
            <h4>Jam Buka</h4>
            <p>
              Senin - Minggu <br />
              06.00 - 20.00
            </p>
          </div>
          <div className="col-sm-3 col-12">
            <h4>Hubungi Kami</h4>
            <p>
              <a href="tel:+6287808330991">+62 878-0833-0991</a> <br />
              <a href="mailto:service@zeinpemalang.com">
                service@zeinpemalang.com
              </a>
            </p>
          </div>
          <div className="col-sm-3 col-12">
            <h4>Ikuti Kami</h4>
            <p>
              <a href="https://instagram.com/warung.pemalang">Instagram</a>
            </p>
          </div>
        </div>

        <div className="row text-center">
          <div className="col-12">
            <p>© 2022 - 2024 Warung Nasi Zein Pemalang (v2.0) <br /> Powered by: <a href="https://awiez.com">awiez.com</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;
