import HomeFacility from "../components/HomeFacility";
import HomeFooter from "../components/HomeFooter";
import HomeHeader from "../components/HomeHeader";
import HomeInfo from "../components/HomeInfo";
import HomeMainMenu from "../components/HomeMainMenu";
import HomePaymentChannel from "../components/HomePaymentChannel";
import HomeServices from "../components/HomeServices";
const Home = () => {
  return (
    <div>
      <HomeHeader />
      <HomeInfo/>
      <HomeMainMenu/>
      <HomeFacility/>
      <HomeServices/>
      <HomePaymentChannel/>
      <HomeFooter/>
    </div>
  );
};

export default Home;
