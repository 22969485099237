import { styles } from "./HomeAssets";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

const HomePaymentChannel = () => {
  return (
    <div className={`${styles.section} ${styles.payment_channel}`}>
      <div className="container">
        <h2>Metode Pembayaran Beragam</h2>
        <p>
          Untuk memudahkan kamu dalam melakukan pembayaran, kami menyediakan
          berbagai macam metode pembayaran, seperti: QRIS, Transfer Bank, dan
          PaymentLink.
        </p>
        <>
          <Swiper
            slidesPerView={2}
            spaceBetween={0}
            autoplay={true}
            loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
                <img className={styles.bank_logo} src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/QRIS_logo.svg/2560px-QRIS_logo.svg.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img className={styles.bank_logo} src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Shopee.svg/1200px-Shopee.svg.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img className={styles.bank_logo} src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Gopay_logo.svg/2560px-Gopay_logo.svg.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img className={styles.bank_logo} src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Logo_dana_blue.svg/2560px-Logo_dana_blue.svg.png" alt="" />
            </SwiperSlide>
          </Swiper>
        </>
      </div>
    </div>
  );
};

export default HomePaymentChannel;
